import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"

export default () => (
  <Layout current={"404"}>
    <Header headerText={"Ooops! - Not Found!"} />
    <h2>The Page you were looking for, could not be found!</h2>
  </Layout>
)
